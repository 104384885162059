// MotorcycleForm.jsx
import React, { useState, useEffect } from 'react';
import { getMotorcycles } from '../../services/apiService';
import MotorcycleDetails from './MotorcycleDetails';
import CustomDatePicker from './CustomDatePicker';
import { countries } from 'countries-list'; // Импортируем список стран из пакета
import BirthdayDatePicker from './BirthdayDatePicker';
import ThirdDatePicker from './ThirdPicker';
import './Form.css';
import PDF from './pdf'
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
import PDFComponent from './pdf';
import PDFComponent2 from './pdf2';
import PDFComponent3 from './pdf3';
import { useLocation } from 'react-router-dom';
import { getOrders } from '../../services/apiService';
import { getOrdersWithMotorcycleDetails } from '../../services/apiService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Fireworks from './Firework';




function MotorcycleForm({ selectedMotorcycle, setSelectedMotorcycle, formClass, setFormClass, orderId, toggleForm2Class, form2Class, username, userFullName }) {
    console.log('Received userFullName in MotorcycleForm:', userFullName); // Проверка значения
    console.log('Received username in MotorcycleForm:', username); // Проверка значения

    const [combiDays, setCombiDays] = useState(0);
    const [weeklyPeriods, setWeeklyPeriods] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});

    const [wochenend, setWochenend] = useState(0);
    const [wochenend2, setWochenend2] = useState(0);

    


    const euCountries = [
        "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czech Republic",
        "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary",
        "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta",
        "Netherlands", "Poland", "Portugal", "Romania", "Slovakia", "Slovenia",
        "Spain", "Sweden"
    ];

    const allCountries = countries ? Object.values(countries).map(country => country.name) : [];
    // Разделяем страны на EU и остальные
    const euCountryNames = allCountries.filter(country => euCountries.includes(country)).sort();
    const nonEuCountryNames = allCountries.filter(country => !euCountries.includes(country)).sort();

    // Объединяем списки
    const sortedCountryNames = [...euCountryNames, ...nonEuCountryNames];

    const [fieldClass, setFieldClass] = useState('');
    const [initialVisit3, setInitialVisit3] = useState(true); // Флаг для отслеживания первого посещения страницы с мотоциклами


    const [orderStatus, setOrderStatus] = useState(null); // Состояние для хранения статуса заказа

    const [orderData, setOrderData] = useState(null); // Состояние для хранения данных заказа
    const [orderData2, setOrderData2] = useState(null); // Состояние для хранения данных заказа
    const location = useLocation();


    const countryNames = countries ? Object.values(countries).map(country => country.name) : [];
    const [selectedTitle, setSelectedTitle] = useState(''); // состояние выбранного заголовка (Herr или Frau)
    const [selectedMotorcycle2, setSelectedMotorcycle2] = useState('');
    const [firstName, setFirstName] = useState(''); // состояние выбранного заголовка (Herr или Frau)
    const [lastName, setLastName] = useState(''); // состояние выбранного заголовка (Herr или Frau)

    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);

    const [itionalInfo, setItionalInfo] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [ausweisB, setAusweisB] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [country, setCountry] = useState('');

    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [zipCode, setZipCode] = useState('');

    const [motorcycles, setMotorcycles] = useState([]);
    // const [selectedMotorcycle, setSelectedMotorcycle] = useState('');
    const [selectedRate, setSelectedRate] = useState(''); // Изменено на пустую строку
    const [selectedRate2, setSelectedRate2] = useState(''); // Изменено на пустую строку
    const [startDate, setStartDate] = useState(null); // Добавлено состояние для начальной даты
    const [endDate, setEndDate] = useState(null);
    const [days, setDays] = useState(0); // Количество дней
    const [price, setPrice] = useState(0); // Цена
    const [priceWithoutPaket, setPriceWithoutPaket] = useState(0); // Цена
    const [price2WithoutPaket, setPrice2WithoutPaket] = useState(0); // Цена

    const [price2, setPrice2] = useState(0); // Цена
    const [startTime, setStartTime] = useState(null); // Время начала аренды
    const [endTime, setEndTime] = useState(null); // Время окончания аренды
    const [checkedLocation, setCheckedLocation] = useState(false);


    const [formLeftFilled, setFormLeftFilled] = useState(false);

    const [zusatzpaketChecked, setZusatzpaketChecked] = useState(false);
    const [agbChecked, setAgbChecked] = useState(false);
    const [agb1Checked, setAgb1Checked] = useState(false);
    const [driverLicenseNumber, setDriverLicenseNumber] = useState('');
    const [driverLicenseClass, setDriverLicenseClass] = useState('');

    const [mainId, setMainId] = useState(null);

    const [submitted, setSubmitted] = useState(false); // Состояние для отслеживания отправки формы

    const validateFields = () => {
        let errors = {};

        if (!firstName.trim()) {
            errors.firstName = 'Vorname ist erforderlich.';
        }

        if (!lastName.trim()) {
            errors.lastName = 'Nachname ist erforderlich.';
        }

        if (!email.trim()) {
            errors.email = 'Email ist erforderlich.';
        }

        if (!phoneNumber.trim()) {
            errors.phoneNumber = 'Telefonnummer ist erforderlich.';
        } else if (phoneNumber.replace(/[^0-9]/g, '').length < 10) {
            errors.phoneNumber = 'Telefonnummer muss mindestens 10 Zeichen lang sein.';
        }

        //  if (!agb1Checked) {
        //     errors.agreements1 = 'Sie müssen den AGBs und Datenschutzbestimmungen zustimmen.';
        // }
        // Добавьте здесь другие проверки для остальных полей




        return errors;
    };


    const validateFields2 = () => {
        let errors = {};

        if (!selectedDate2) {
            errors.birthday = 'Geburtsdatum ist erforderlich.';
        }


        if (!street.trim()) {
            errors.street = 'Strasse ist erforderlich.';
        }

        if (!houseNumber.trim()) {
            errors.houseNumber = 'Hausnummer ist erforderlich.';
        }

        if (!zipCode.trim()) {
            errors.zipCode = 'PLZ ist erforderlich.';
        }

        if (!city.trim()) {
            errors.city = 'Ort ist erforderlich.';
        }

        if (!documentNumber.trim()) {
            errors.documentNumber = 'Ausweisnummer ist erforderlich.';
        }

        if (!documentType.trim()) {
            errors.documentType = 'Ausweisart ist erforderlich.';
        }

        if (!ausweisB.trim()) {
            errors.ausweisB = 'Ausstellungsbehörde ist erforderlich.';
        }

        if (!country.trim()) {
            errors.country = 'Ausstellendes Land ist erforderlich.';
        }

        if (!driverLicenseNumber.trim()) {
            errors.driverLicenseNumber = 'Führerscheinnummer ist erforderlich.';
        }

        if (!driverLicenseClass.trim()) {
            errors.driverLicenseClass = 'Führerscheinklasse ist erforderlich.';
        }

        if (!itionalInfo.trim()) {
            errors.itionalInfo = 'Ausstellungsbehörde ist erforderlich.';
        }

        if (!selectedDate3) {
            errors.thirdDate = 'Datum ist erforderlich.';
        }

        return errors;
    };


    const smoothScroll = (targetId, offset) => {
        const target = document.getElementById(targetId);
        if (target) {
            const yOffset = offset || -60; // Здесь можно настроить нужное вам смещение вверх
            const y = target.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };


    const handleButtonClick = () => {
        toggleForm2Class();
        // Добавляем тайм-аут, чтобы дать DOM время отрисовать изменения
        setTimeout(() => smoothScroll('formRight'), 300);
    };

    const handleButtonClick2 = () => {
        toggleFieldClass();
        // Добавляем тайм-аут, чтобы дать DOM время отрисовать изменения
        setTimeout(() => smoothScroll('personDaten'), 300);
    };


    const handleZusatzpaketChange = (event) => {
        setZusatzpaketChecked(event.target.checked);
    };

    const handleAgbChange = (event) => {
        setAgbChecked(event.target.checked);
    };

    const handleAgb1Change = (event) => {
        setAgb1Checked(event.target.checked);
    };


    const toggleFieldClass = () => {
        setFieldClass(prevClass => prevClass === 'hidden' ? '' : 'hidden');
    };

    useEffect(() => {

        // Проверяем текущий путь URL
        if ((location.pathname.includes('/motorcycle')) || !orderData) {
            // Если мы на странице с мотоциклом, скрываем форму, если это первое посещение
            if (initialVisit3) {
                setFieldClass('hidden');
                setInitialVisit3(false); // Устанавливаем флаг, что первое посещение выполнено
            }
        } else {
            // Если мы покинули страницу с мотоциклами, показываем форму
            setFieldClass('');
            setInitialVisit3(true); // Сбрасываем флаг первого посещения при покидании страницы с мотоциклами
        }
    }, [location.pathname, initialVisit3]);



    const fetchOrderStatus = async () => {
        try {
            if (!orderId) {
                return; // Если orderId равен null, просто выходим из функции
            }

            const orders = await getOrders();
            const order = orders.find(order => order.formlink === orderId);
            if (order) {
                console.log('formlink', order.formlink);
                setOrderStatus(order.status);
                console.log('Order status:', order.status);



            } else {
                console.log('Order not found');
            }
        } catch (error) {
            console.error('Error fetching order status:', error);
        }
    };

    useEffect(() => {

        fetchOrderStatus();

    }, [orderId]);


    const fetchOrderDetails = async (formlink) => {
        try {
            const orders = await getOrders(); // Получаем все заказы
            const order = orders.find(order => order.formlink === formlink); // Находим заказ по formlink
            if (order) { // Проверяем, что заказ существует
                console.log('Order details:', order); // Выводим данные о заказе в лог
                setOrderData(order); // Установим данные заказа в orderData
                setMainId(order.id);
            } else {
                console.log('Order not found');
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };


    const formatOrderDate = (dateString) => {
        // Проверяем, что передана дата
        if (!dateString) {
            return null;
        }

        // Создаем новый объект Date из строки даты
        const formattedDate = new Date(dateString);

        // Возвращаем отформатированную дату
        return formattedDate;
    };


    const formatOrderTime = (timeString) => {
        // Проверяем, что передано время
        if (!timeString) {
            return null;
        }

        // Создаем новый объект Date из строки времени
        const formattedTime = new Date(`1970-01-01T${timeString}`);

        // Возвращаем отформатированное время
        return formattedTime;
    };





    useEffect(() => {
        const fetchData = async () => {
            if (!orderData || !orderData.id) {
                // Проверяем, что orderData существует и его id определен
                await fetchOrderDetails(orderId);
            }
            // if (orderData && orderData.id && orderStatus) {
            if (orderData && orderData.id) {
                // Убеждаемся, что есть данные о заказе, его id определен и статус заказа установлен
                setSelectedMotorcycle(orderData.selectedMotorcycle);
                setSelectedMotorcycle2(orderData.selectedMotorcycle2);
                setSelectedRate(orderData.selectedRate);

                // Отформатировать startDate и установить его в состояние selectedStartDate
                const formattedStartDate = formatOrderDate(orderData.startDate);
                setStartDate(formattedStartDate);
                const formattedEndDate = formatOrderDate(orderData.endDate);
                setEndDate(formattedEndDate);

                const formattedStartTime = formatOrderTime(orderData.startTime);
                setStartTime(formattedStartTime);
                const formattedEndTime = formatOrderTime(orderData.endTime);
                setEndTime(formattedEndTime);

                setFirstName(orderData.firstName);
                setLastName(orderData.lastName);

                setEmail(orderData.email);
                setPhoneNumber(orderData.phoneNumber);
                setZusatzpaketChecked(orderData.zusatzpaket);
                setDays(orderData.days);
                setPrice(orderData.price);
                setPrice2(orderData.price2);
                setPriceWithoutPaket(orderData.priceWithoutPaket);
                setPrice2WithoutPaket(orderData.price2WithoutPaket);
                setCombiDays(orderData.combiDays);
                setWeeklyPeriods(orderData.weeklyPeriods);
            }
        };

        if (orderId) {
            fetchData();
        }
        // }, [orderId, orderData, orderStatus]);
    }, [orderId, orderData]);








    const handleFormLeftChange = () => {
        // Проверяем, заполнены ли все поля formLeft
        // Ваша логика проверки может отличаться, здесь приведен лишь пример
        if (typeof orderStatus !== 'undefined') {
            console.log('order status true');
            setFormLeftFilled(true);
        } else {
            console.log('order status false');
            setFormLeftFilled(false);
        }
    };

    useEffect(() => {
        handleFormLeftChange();
    }, [orderStatus]);




    // useEffect(() => {
    //     // Проверяем текущий путь URL только если проверка еще не выполнена
    //     if (!checkedLocation && location.pathname.includes('/motorcycle')) {
    //         // Если путь содержит '/motorcycle' и проверка еще не выполнена, скрываем форму
    //         setFormClass('hidden');
    //         // Устанавливаем флаг, чтобы указать, что проверка уже выполнена
    //         setCheckedLocation(true);
    //     } else if (checkedLocation && !location.pathname.includes('/motorcycle')) {
    //         // Если проверка уже выполнена и мы покинули страницу с мотоциклами, показываем форму
    //         setFormClass('hidden');
    //     }
    // }, [location.pathname, checkedLocation, setFormClass]);

    const handleMotorcycleSelectChange = (e) => {
        setSelectedMotorcycle(e.target.value);
    };


    const handleMotorcycle2SelectChange = (event) => {
        setSelectedMotorcycle2(event.target.value);
    };


    const handleTitleChange = (event) => {
        setSelectedTitle(event.target.value);
    };

    useEffect(() => {
        const storedMotorcycle = localStorage.getItem('selectedMotorcycle');
        if (storedMotorcycle) {
            setSelectedMotorcycle(storedMotorcycle);
        }
    }, []);

    const API_BASE_URL = 'https://admin.motorrad-mieten-kaernten.at';

    // const saveOrderToDatabase = async (orderData2) => {
    //     try {
    //         const response = await fetch(`${API_BASE_URL}/items/Orders`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(orderData2)
    //         });
    //         if (response.ok) {
    //             console.log('Order saved successfully!');
    //         } else {
    //             console.error('Failed to save order:', response.statusText);
    //         }
    //     } catch (error) {
    //         console.error('Error saving order:', error);
    //     }
    // };
    const saveOrderToDatabase = async (orderData) => {
        try {
            const response = await fetch(`${API_BASE_URL}/items/Orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            return response; // Возвращаем объект Response
        } catch (error) {
            console.error('Error in saveOrderToDatabase:', error);
            throw error;
        }
    };



    const updateOrderInDatabase = async (orderData2) => {
        try {
            console.log('send data----', orderData2);
            const response = await fetch(`${API_BASE_URL}/items/Orders/${mainId}`, {
                method: 'PATCH', // Или PATCH, в зависимости от вашего API
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData2)
            });
            if (response.ok) {
                console.log('Order updated successfully!');
            } else {
                console.error('Failed to update order:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };


    const formatToServerDate = (date) => {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${year}-${month}-${day}T${hours}:${minutes}`;
        } else {
            return ''; // или какое-то другое значение по умолчанию, если date равно null
        }
    };

    function getFreeKilometers(selectedRate1, days, combiDays, weeklyPeriods) {
        let freeKilometers = 0;
        console.log('Calculating free kilometers:', { selectedRate1, days });
        if (selectedRate1 === 'daily') {
            freeKilometers = 400 * days;
        } else if (selectedRate1 === 'weekly') {
            freeKilometers = 800;
        } else if (selectedRate1 === 'combi') {
            freeKilometers = (combiDays ? combiDays * 400 : 0) + (weeklyPeriods ? weeklyPeriods * 800 : 0);
        }
        console.log('Calculated free kilometers:', freeKilometers);
        return freeKilometers;
    }

    function getFreeKilometers2(selectedRate1, days, combiDays, weeklyPeriods) {
        let freeKilometers = 0;
        if (selectedRate1 === 'daily') {
            freeKilometers = 400;
        } else if (selectedRate1 === 'weekly') {
            freeKilometers = 800;
        } else if (selectedRate1 === 'combi') {
            freeKilometers = 400;
        }
        return freeKilometers;
    }

    // let zusatzpaket;
    // useEffect(() => {
    //     if (location.pathname.startsWith("/orders")) {
    //         setZusatzpaketChecked(orderData?.zusatzpaketChecked);
    //     } else {
    //         if (!orderData){
    //             zusatzpaket = document.getElementById('zusatzpaket').checked;
    //         }
    //     }
    // }, [location.pathname, orderData]);


    // let statusMotorcycle1;
    // useEffect(() => {
    //     if (sessionStorage.getItem('userId')) {
    //         statusMotorcycle1 = true;
    //     } else {
    //         if (orderData){
    //             statusMotorcycle1 = orderData.statusMotorcycle1
    //         }
    //     }
    // }, [sessionStorage.getItem, orderData]);

    // let zusatzpaket = document.getElementById('zusatzpaket').checked;


    const handleSubmit = async (event) => {
        event.preventDefault(); // Предотвращаем обновление страницы по умолчанию

        // Сброс сообщений об ошибке
        setErrorMessage('');
        setFieldErrors({});


        // Проверка полей формы
        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return; // Прекращаем выполнение, если есть ошибки
        }

        try {

            const isOrderStatusTrue = orderStatus === true;

            // Форматируем время начала и окончания аренды перед отправкой на сервер
            const formattedStartTime = startTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
            const formattedEndTime = endTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
            const formattedStartDate = formatToServerDate(startDate);
            const formattedEndDate = formatToServerDate(endDate);

            let zusatzpaket;
            if (orderData) {
                zusatzpaket = orderData.zusatzpaketChecked;
            } else {
                zusatzpaket = document.getElementById('zusatzpaket').checked;
            }

            let statusMotorcycle1;
            let statusMotorcycle2;
            if (sessionStorage.getItem('userId')) {
                statusMotorcycle1 = true;
                statusMotorcycle2 = false;
            } else {
                if (orderData) {
                    statusMotorcycle1 = orderData.statusMotorcycle1;
                    statusMotorcycle2 = orderData.statusMotorcycle2;
                }
            }

            let firstApprove;
            let secondApprove;
            let firstApproveTime;
            let secondApproveTime;
            let userStatus;
            if (sessionStorage.getItem('userId')) {
                if (!userFullName) {
                    console.error("userFullName is not set"); // Сообщение об ошибке для отладки
                }
            
                const now = new Date();
                firstApprove = userFullName;
                secondApprove = userFullName;
                firstApproveTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); // Добавляем 2 часа к текущему времени
                secondApproveTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); // Добавляем 2 часа к текущему времени

            }
            if (sessionStorage.getItem('userId')) {
                userStatus = true;
            } else {
                userStatus = false;
            }


            const agbInput = document.getElementById('agreements');
            const agb1Input = document.getElementById('agreements1');
            const agb = agbInput ? agbInput.checked : false;
            const agb1 = agb1Input ? agb1Input.checked : false;

            const formatteBirthdDate = formatToServerDate(selectedDate2);
            const formattedThirdDate = formatToServerDate(selectedDate3);
            const streetInput = event.target.street;
            const street = streetInput ? streetInput.value : null;
            const houseNumberInput = event.target.houseNumber;
            const houseNumber = houseNumberInput ? houseNumberInput.value : null;
            const zipCodeInput = event.target.zipCode;
            const zipCode = zipCodeInput ? zipCodeInput.value : null;
            const cityInput = event.target.city;
            const city = cityInput ? cityInput.value : null;
            const emailInput = event.target.email;
            const email = emailInput ? emailInput.value : null;
            const phoneNumberInput = event.target.phoneNumber;
            const phoneNumber = phoneNumberInput ? phoneNumberInput.value : null;
            const driverLicenseNumberInput = document.getElementById('driverLicenseNumber');
            const driverLicenseNumber = driverLicenseNumberInput ? driverLicenseNumberInput.value : null;

            const driverLicenseClassInput = document.getElementById('driverLicenseClass');
            const driverLicenseClass = driverLicenseClassInput ? driverLicenseClassInput.value : null;

            const driverLicenseSinceInput = document.getElementById('driverLicenseSince');
            const driverLicenseSince = driverLicenseSinceInput ? driverLicenseSinceInput.value : null;

            const getMotorcycleByName = (motorcycles, name) => {
                return motorcycles.find(motorcycle => motorcycle.Name === name) || null;
            };
            const motorcycle1Name = selectedMotorcycle; // Получаем имя выбранного мотоцикла
            const motorcycle1 = getMotorcycleByName(motorcycles, motorcycle1Name); // Получаем объект выбранного мотоцикла

            const motorcycle2Name = selectedMotorcycle2; // Получаем имя выбранного мотоцикла 2
            const motorcycle2 = getMotorcycleByName(motorcycles, motorcycle2Name); // Получаем объект выбранного мотоцикла 2
            console.log('rate1', getSelectedMotorcycleRate(selectedMotorcycle));
            console.log('rate2', getSelectedMotorcycleRate2(selectedMotorcycle2));
            // Формируем объект с данными заказа
            const orderData2 = {
                wochenend,
                wochenend2,
                rate1: getSelectedMotorcycleRate(selectedMotorcycle),
                rate2: getSelectedMotorcycleRate2(selectedMotorcycle2),
                motorcycle1: motorcycle1 ? {
                    name: motorcycle1.name,
                    marke: motorcycle1.marke,
                    modell: motorcycle1.modell,
                    id: motorcycle1.id,
                    daily: motorcycle1.daily_rate,
                    weekly: motorcycle1.weekend,
                    name: motorcycle1.Name,
                    fin: motorcycle1.fin,
                    kennzeichen: motorcycle1.kennzeichen,
                    certificateNumber: motorcycle1.certificateNumber
                } : null, // Сохраняем id, daily и weekly выбранного мотоцикла или null, если мотоцикл не найден
                motorcycle2: motorcycle2 ? {
                    name: motorcycle2.name,
                    marke: motorcycle2.marke,
                    modell: motorcycle2.modell,
                    id: motorcycle2.id,
                    daily: motorcycle2.daily_rate,
                    weekly: motorcycle2.weekend,
                    name: motorcycle2.Name,
                    fin: motorcycle2.fin,
                    kennzeichen: motorcycle2.kennzeichen,
                    certificateNumber: motorcycle2.certificateNumber
                } : null, // Сохраняем id, daily и weekly выбранного мотоцикла 2 или null, если мотоцикл не найден
                selectedMotorcycle,
                selectedMotorcycle2,
                selectedRate,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                days,
                price,
                price2,
                zusatzpaket,
                itionalInfo,
                documentType,
                documentNumber,
                ausweisB,
                country,
                anrede: selectedTitle,
                firstName,
                lastName,
                birthDate: formatteBirthdDate,
                thirdDate: formattedThirdDate,
                street,
                houseNumber,
                zipCode,
                city,
                email,
                phoneNumber,
                driverLicenseNumber,
                driverLicenseClass,
                driverLicenseSince,
                status: orderStatus !== null ? orderStatus : false,
                agb,
                agb1,
                freeKm: getFreeKilometers(selectedRate, days, combiDays, weeklyPeriods),
                freeKmDefault: getFreeKilometers2(selectedRate, days, combiDays, weeklyPeriods),
                statusMotorcycle1,
                statusMotorcycle2,
                priceWithoutPaket,
                price2WithoutPaket,
                firstApprove,
                firstApproveTime,
                secondApprove,
                secondApproveTime,
                userStatus,
                combiDays,
                weeklyPeriods
            };

            if (location.pathname.startsWith("/orders")) {

                const errors = validateFields2();
                if (Object.keys(errors).length > 0) {
                    setFieldErrors(errors);
                    return; // Прекращаем выполнение, если есть ошибки
                }
                // Выполняем обновление заказа
                await updateOrderInDatabase(orderData2);
                setSubmitted(true);
                setTimeout(() => {
                    window.location.replace('/');
                }, 3000);
                console.log('Order Updated:', orderData2);
            } else {
                // Выполняем добавление нового заказа
                const response = await saveOrderToDatabase(orderData2);
                if (response.ok) {
                    const responseData = await response.json();
                    console.log('Response Data:', responseData);
                    const orderId = responseData.data.id;
                    orderData2.id = orderId;
                    setOrderData(orderData2);
                    setSubmitted(true);
                    console.log('Order Data:', orderData2);

                    if (!sessionStorage.getItem('userId')) {
                        setTimeout(() => {
                            window.location.replace('/');
                        }, 3000);
                    }
                    console.log('New Order Created:', orderData2);
                } else {
                    const errorText = await response.text();
                    throw new Error(`Server responded with status ${response.status}: ${errorText}`);
                }
            }
        } catch (error) {
            console.error('Submission Error:', error);
            setErrorMessage('Es gab einen Fehler beim Absenden des Formulars. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.');
        }
    };






    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMotorcycles();
                setMotorcycles(data);
                setSelectedRate('daily'); // Устанавливаем selectedRate в "daily" после загрузки данных
                setSelectedRate2('daily'); // Устанавливаем selectedRate в "daily" после загрузки данных

                // Устанавливаем время для ежедневной аренды (например, 8:00 утра)
                const startTimeDaily = new Date();
                startTimeDaily.setHours(8); // Устанавливаем часы
                startTimeDaily.setMinutes(30); // Устанавливаем минуты
                setStartTime(startTimeDaily);

                // Устанавливаем время окончания для ежедневной аренды (например, 18:00 вечера)
                const endTimeDaily = new Date();
                endTimeDaily.setHours(16); // Устанавливаем часы
                endTimeDaily.setMinutes(30); // Устанавливаем минуты
                setEndTime(endTimeDaily);

            } catch (error) {
                console.error('Error fetching motorcycles:', error);
            }
        };

        fetchData();
    }, []);

    // Функция для получения тарифа для выбранного мотоцикла
    const getSelectedMotorcycleRate = (selectedMotorcycle) => {
        const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
        if (!selectedMotorcycleObject) return '';

        if (selectedRate === 'daily') {
            return selectedMotorcycleObject.daily_rate;
        } else if (selectedRate === 'weekly') {
            return selectedMotorcycleObject.weekend;
        } else if (selectedRate === 'combi') {
            return selectedMotorcycleObject.combi; // Учитываем стоимость для комбинированной опции
        } else {
            return '';
        }
    };

    const getSelectedMotorcycleRatePrice = (selectedMotorcycle) => {
        const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
        return selectedMotorcycleObject ? selectedMotorcycleObject.weekend : 0; // Возвращаем цену
    };

    useEffect(() => {
        const price = getSelectedMotorcycleRatePrice(selectedMotorcycle);
        setWochenend(price);
    }, [selectedMotorcycle, motorcycles]);

    const getSelectedMotorcycleRatePrice2 = (selectedMotorcycle2) => {
        const selectedMotorcycle2Object = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle2);
        return selectedMotorcycle2Object ? selectedMotorcycle2Object.weekend : 0; // Возвращаем цену
    };

    useEffect(() => {
        const price = getSelectedMotorcycleRatePrice(selectedMotorcycle);
        setWochenend2(price);
    }, [selectedMotorcycle2, motorcycles]);


    // Функция для получения тарифа для выбранного мотоцикла
    const getSelectedMotorcycleRate2 = (selectedMotorcycle2) => {
        const selectedMotorcycle2Object = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle2);
        // return selectedMotorcycle2Object ? selectedRate2 === 'daily' ? selectedMotorcycle2Object.daily_rate : selectedMotorcycle2Object.weekend : '';
        if (!selectedMotorcycle2Object) return '';
        if (selectedRate === 'daily') {
            return selectedMotorcycle2Object.daily_rate;
        } else if (selectedRate === 'weekly') {
            return selectedMotorcycle2Object.weekend;
        } else if (selectedRate === 'combi') {
            return selectedMotorcycle2Object.combi; // Учитываем стоимость для комбинированной опции
        } else {
            return '';
        }

    };

    // Функция для получения изображения выбранного мотоцикла
    const getSelectedMotorcycleImage = (selectedMotorcycle) => {
        const selectedMotorcycleObject = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle);
        return selectedMotorcycleObject ? selectedMotorcycleObject.image : '';
    };

    // Функция для получения изображения выбранного мотоцикла
    const getSelectedMotorcycleImage2 = (selectedMotorcycle2) => {
        const selectedMotorcycle2Object = motorcycles.find(motorcycle => motorcycle.Name === selectedMotorcycle2);
        return selectedMotorcycle2Object ? selectedMotorcycle2Object.image : '';
    };



    const handleRateChange = (rate) => {
        // Сбросить даты
        setStartDate(null);
        setEndDate(null);
        // Установить новый выбранный тариф
        setSelectedRate(rate);
        setSelectedRate2(rate);

        // Установить время в соответствии с выбранным тарифом
        if (rate === 'daily') {
            // Установить время для ежедневной аренды (например, 8:00 утра)
            const startTimeDaily = new Date();
            startTimeDaily.setHours(8); // Устанавливаем часы
            startTimeDaily.setMinutes(30); // Устанавливаем минуты
            setStartTime(startTimeDaily);

            // Установить время окончания для ежедневной аренды (например, 6:00 вечера)
            const endTimeDaily = new Date();
            endTimeDaily.setHours(16); // Устанавливаем часы
            endTimeDaily.setMinutes(30); // Устанавливаем минуты
            setEndTime(endTimeDaily);
        } else if (rate === 'weekly') {
            // Установить время для недельной аренды (например, 9:00 утра)
            const startTimeWeekly = new Date();
            startTimeWeekly.setHours(16); // Устанавливаем часы
            startTimeWeekly.setMinutes(30); // Устанавливаем минуты
            setStartTime(startTimeWeekly);

            // Установить время окончания для недельной аренды (например, 5:00 вечера)
            const endTimeWeekly = new Date();
            endTimeWeekly.setHours(9); // Устанавливаем часы
            endTimeWeekly.setMinutes(0); // Устанавливаем минуты
            setEndTime(endTimeWeekly);
        } else if (rate === 'combi') {
            // Установить время для недельной аренды (например, 9:00 утра)
            const startTimeCombi = new Date();
            startTimeCombi.setHours(8);
            startTimeCombi.setMinutes(30);
            setStartTime(startTimeCombi);

            const endTimeCombi = new Date();
            endTimeCombi.setHours(16);
            endTimeCombi.setMinutes(30);
            setEndTime(endTimeCombi);
        }
    };


    // window.addEventListener('scroll', function() {
    //     var scrollPosition = window.scrollY;
    //     var motoDetails = document.querySelector('.motoDetails');
    //     var thresholdStart = 6800; // Порог прокрутки, после которого нужно изменить положение элемента
    //     var thresholdEnd = 8350; // Второй порог прокрутки, после которого нужно прекратить изменение положения элемента

    //     if (scrollPosition > thresholdStart && scrollPosition < thresholdEnd) {
    //         motoDetails.classList.add('fixed');
    //     } else {
    //         motoDetails.classList.remove('fixed');
    //     }
    // });








    console.log('orderData', orderData);
    console.log('orderData2', orderData2);




    const handleInputChange = (e, setFieldValue) => {
        const { id, value } = e.target;
        setFieldValue(value);

        if (fieldErrors[id]) {
            setFieldErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[id];
                return newErrors;
            });
        }
    };
    const handlePhoneChange = (phone) => {
        setPhoneNumber(phone);

        if (fieldErrors.phoneNumber) {
            setFieldErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.phoneNumber;
                return newErrors;
            });
        }
    };

    const handleInputChange2 = (date) => {
        // Обновляем дату и очищаем ошибку, если дата валидна
        setSelectedDate2(date);
        if (date) {
            setFieldErrors(prevErrors => {
                const { birthday, ...rest } = prevErrors;
                return rest;
            });
        } else {
            setFieldErrors(prevErrors => ({
                ...prevErrors,
                birthday: 'Geburtsdatum ist erforderlich.'
            }));
        }
    };

    const handleInputChange3 = (date) => {
        // Обновляем дату и очищаем ошибку, если дата валидна
        setSelectedDate3(date);
        if (date) {
            setFieldErrors(prevErrors => {
                const { thirdDate, ...rest } = prevErrors; // Исправлено с birthday на thirdDate
                return rest;
            });
        } else {
            setFieldErrors(prevErrors => ({
                ...prevErrors,
                thirdDate: 'Datum ist erforderlich.' // Убедитесь, что ключ совпадает
            }));
        }
    };






    // Условный рендеринг formRight
    const renderFormRight = () => {
        console.log('LOOOOOOP!!!!-------3');

        console.log(orderStatus);
        // Если все поля formLeft заполнены, рендерим formRight
        // if ((formLeftFilled && sessionStorage.getItem('userId')) || (orderStatus && location.pathname.startsWith("/orders"))) {
        if ((formLeftFilled && sessionStorage.getItem('userId')) || (location.pathname.startsWith("/orders"))) {
            console.log('LOOOOOOP!!!!------------4');

            return (
                <div className='formRight' id='formRight'>

                    {/* 
                    <div>
                        <label htmlFor="itionalInfo">Additional Information:</label>
                        <input
                            type="text"
                            id="itionalInfo"
                            name="itionalInfo"
                            placeholder="Enter additional information"
                            value={itionalInfo}
                            onChange={(e) => setItionalInfo(e.target.value)}
                        />
                    </div> */}





                    <h2>Ausweisangaben</h2>

                    {/* Поле для ввода номера документа */}
                    <div className='field'>
                        <label htmlFor="documentNumber">Ausweisnummer:</label>
                        <input
                            type="text"
                            id="documentNumber"
                            name="documentNumber"
                            placeholder=""
                            value={documentNumber}
                            onChange={(e) => handleInputChange(e, setDocumentNumber)}
                        />
                    </div>

                    {fieldErrors.documentNumber && <div className="error-message">{fieldErrors.documentNumber}</div>}

                    {/* Выбор типа документа */}
                    <div className='field'>
                        <label htmlFor="documentType">Ausweisart:</label>
                        <select
                            id="documentType"
                            name="documentType"
                            value={documentType}
                            onChange={(e) => {
                                setDocumentType(e.target.value);
                                if (fieldErrors.documentType) {
                                    setFieldErrors(prevErrors => {
                                        const { documentType, ...rest } = prevErrors;
                                        return rest;
                                    });
                                }
                            }}
                        >
                            <option value="">Bitte wählen Sie eine Option</option>
                            <option value="Reisepass">Reisepass</option>
                            <option value="Personalausweis">Personalausweis</option>
                        </select>
                    </div>
                    {fieldErrors.documentType && <div className="error-message">{fieldErrors.documentType}</div>}


                    <div className='field'>
                        <label htmlFor="ausweisB">Ausstellungsbehörde:</label>
                        <input
                            type="text"
                            id="ausweisB"
                            name="ausweisB"
                            placeholder=""
                            value={ausweisB}
                            onChange={(e) => handleInputChange(e, setAusweisB)}
                        // required
                        />
                    </div>
                    {fieldErrors.ausweisB && <div className="error-message">{fieldErrors.ausweisB}</div>}

                    <div className='field'>
                        <label htmlFor="country">Ausstellendes Land:</label>
                        <select
                            id="country"
                            name="country"
                            value={country}
                            onChange={(e) => {
                                setCountry(e.target.value);
                                if (fieldErrors.country) {
                                    setFieldErrors(prevErrors => {
                                        const { country, ...rest } = prevErrors;
                                        return rest;
                                    });
                                }
                            }}
                        >
                            <option value=""></option>
                            <optgroup label="EU-Länder">
                                {euCountryNames.map((country, index) => (
                                    <option key={index} value={country}>{country}</option>
                                ))}
                            </optgroup>
                            <optgroup label="Nicht-EU-Länder">
                                {nonEuCountryNames.map((country, index) => (
                                    <option key={index} value={country}>{country}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                    {fieldErrors.country && <div className="error-message">{fieldErrors.country}</div>}











                    {/* <div>
                        <label>Anrede:</label>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    value="Herr"
                                    checked={selectedTitle === 'Herr'}
                                    onChange={handleTitleChange}
                                />
                                Herr
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Frau"
                                    checked={selectedTitle === 'Frau'}
                                    onChange={handleTitleChange}
                                />
                                Frau
                            </label>
                        </div>
                    </div> */}

                    {/* <div>
                        <label htmlFor="firstName">Vorname:</label>
                        <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        // required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName">Nachname:</label>
                        <input
                            type="text"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        // required
                        />
                    </div> */}




                    <h2>Führerscheinangaben</h2>

                    <div style={{ marginBottom: '35px' }}>
                        <div className='field'>
                            <label htmlFor="driverLicenseNumber">Führerscheinnummer:</label>
                            <input type="text" id="driverLicenseNumber" name="driverLicenseNumber" onChange={(e) => handleInputChange(e, setDriverLicenseNumber)} />
                        </div>
                        {fieldErrors.driverLicenseNumber && <div className="error-message">{fieldErrors.driverLicenseNumber}</div>}
                        <div className='field'>
                            <label htmlFor="driverLicenseClass">Führerscheinklasse:</label>
                            <select id="driverLicenseClass" name="driverLicenseClass"
                                onChange={(e) => {
                                    setDriverLicenseClass(e.target.value);
                                    if (fieldErrors.driverLicenseClass) {
                                        setFieldErrors(prevErrors => {
                                            const { driverLicenseClass, ...rest } = prevErrors;
                                            return rest;
                                        });
                                    }
                                }}>
                                <option value="">Bitte wählen Sie eine Option</option>
                                <option value="A">A</option>
                                <option value="A2">A2</option>
                                <option value="kein EU Führerschein">Kein EU Führerschein</option>
                            </select>
                        </div>
                        {fieldErrors.driverLicenseClass && <div className="error-message">{fieldErrors.driverLicenseClass}</div>}

                        <div className='field'>
                            <label htmlFor="itionalInfo">Ausstellungsbehörde:</label>
                            <input
                                type="text"
                                id="itionalInfo"
                                name="itionalInfo"
                                placeholder=""
                                value={itionalInfo}
                                onChange={(e) => handleInputChange(e, setItionalInfo)}
                            />
                        </div>
                        {fieldErrors.itionalInfo && <div className="error-message">{fieldErrors.itionalInfo}</div>}
                        <div className='field'>
                            <label htmlFor="thirdDate">Datum:</label>
                            <ThirdDatePicker selectedDate3={selectedDate3} setSelectedDate3={setSelectedDate3} handleInputChange3={handleInputChange3} />
                        </div>
                        {fieldErrors.thirdDate && <div className="error-message">{fieldErrors.thirdDate}</div>}

                        {/* <div className='field'>
                            <label htmlFor="driverLicenseSince">Führerschein seit:</label>
                            <select id="driverLicenseSince" name="driverLicenseSince" required>
                                <option value="">Bitte wählen Sie eine Option</option>
                                <option value="<1 Jahr">&lt;1 Jahr</option>
                                <option value="1 Jahr">1 Jahr</option>
                                <option value="2 Jahre">2 Jahre</option>
                                <option value="3 Jahre">3 Jahre</option>
                                <option value="4 Jahre">4 Jahre</option>
                                <option value=">4 Jahre">&gt;4 Jahre</option>
                            </select>
                        </div> */}
                    </div>



                    <div>
                        {(sessionStorage.getItem('userId') || orderData) && (
                            <div className='field'>
                                <input type="checkbox" id="agreements" name="agreements" checked={agbChecked} onChange={handleAgbChange} required />
                                <label htmlFor="agreements">Ich bin mit den AGBs und Datenschutzbestimmungen einverstanden.</label>
                            </div>
                        )}
                    </div>



                    <div>
                        {sessionStorage.getItem('userId') && (
                            <div style={{ display: 'flex' }}>
                                <input
                                    type="checkbox"
                                    id="orderStatus"
                                    name="orderStatus"
                                    checked={orderStatus}
                                    onChange={(event) => setOrderStatus(event.target.checked)}
                                />
                                <label style={{ fontSize: '14px' }} htmlFor="orderStatus">Bestätigung</label>

                            </div>
                        )}
                    </div>

                    <div className='restDataBtn'>
                        {sessionStorage.getItem('userId') && (
                            <button type="submit" className='btn' disabled={!agbChecked}>
                                Anfrage registrieren & PDF generieren
                            </button>
                        )}
                        {!sessionStorage.getItem('userId') && (
                            <button type="submit" className='btn'>
                                Restliche Daten senden
                            </button>
                        )}
                    </div>





                </div>

            );
        }
        // Если не все поля formLeft заполнены, ничего не рендерим
        return null;
    };

    const formattedStartDate = location.pathname.startsWith("/orders") && !!orderData ? formatOrderDate(orderData.startDate) : null;
    const formattedEndDate = location.pathname.startsWith("/orders") && !!orderData ? formatOrderDate(orderData.endDate) : null;
    const formattedStartTime = location.pathname.startsWith("/orders") && !!orderData ? formatOrderTime(orderData.startTime) : null;
    const formattedEndTime = location.pathname.startsWith("/orders") && !!orderData ? formatOrderTime(orderData.endTime) : null;
    // const formattedStartDate = location.pathname.startsWith("/orders") && orderStatus && !!orderData ? formatOrderDate(orderData.startDate) : null;
    // const formattedEndDate = location.pathname.startsWith("/orders") && orderStatus && !!orderData ? formatOrderDate(orderData.endDate) : null;
    // const formattedStartTime = location.pathname.startsWith("/orders") && orderStatus && !!orderData ? formatOrderTime(orderData.startTime) : null;
    // const formattedEndTime = location.pathname.startsWith("/orders") && orderStatus && !!orderData ? formatOrderTime(orderData.endTime) : null;



    return (



        <div className={`${formClass}`} id='touren'
            style={{
                padding: location.pathname.startsWith("/orders") ? '0' : '70px'
            }}
        >
            {/* {(orderData && !orderData.agb) || (!orderData && sessionStorage.getItem('userId')) ? ( */}
            <div>
                {submitted && (
                    <div className="message-container">
                        {location.pathname.startsWith("/orders") && orderData ? (
                            <div className='messageGreen'>
                                <p>Ihre zusätzlichen Daten wurden erfolgreich gesendet!</p>
                                <p>Vielen Dank!</p>

                            </div>

                        ) : (
                            <>
                                {sessionStorage.getItem('userId') && username ? (
                                    <div className='messageGreen'>
                                        <p>Danke {username}!</p>
                                        <br />
                                        <p>Buchung № {orderData.id} wurde erstellt</p>
                                        <br />
                                        <p>Jetzt kannst du schon PDF generieren!</p>

                                        <div className='pdfContainer'>
                                            <PDFComponent orderData={orderData} userFullName={userFullName} />
                                            <PDFComponent2 orderData={orderData} userFullName={userFullName} />
                                            <PDFComponent3 orderData={orderData} userFullName={userFullName} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='messageGreen'>
                                        <p>Hey! Vielen Dank für deine Anfrage!</p>
                                        <p>Wir werden diese schnellstmöglich bearbeiten und uns in Kürze bei dir melden</p>
                                    </div>
                                )}

                            </>
                        )}
                    </div>

                )}


                {
                    orderData?.agb ? (
                        <>
                            {username ? (
                                <div className='infoPage'>
                                    <h1>
                                        Liebe(r) {username}!
                                    </h1>
                                    <h1>
                                        Vielen Dank für Deine tolle Arbeit!
                                    </h1>
                                    <div className='firework'>
                                        {/* <Fireworks /> */}
                                        <div class="container1">
                                            <div class="bike-body">
                                                <div class="seat"></div>
                                                <div class="cover"></div>
                                                <div class="lamp"></div>
                                                <div class="motor">
                                                    <div class="part-1">
                                                        <div class="part-1-top"></div>
                                                        <div class="part-1-bottom"></div>
                                                    </div>
                                                    <div class="part-2">
                                                        <div class="part-2-base">
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="front"></div>
                                                <div class="back"></div>
                                            </div>
                                            <div class="tire"></div>
                                            <div class="tire"></div>
                                            <div class="smoke"></div>
                                        </div>


                                    </div>
                                    <p>Dein motorrad-mieten-kaernten.at Team</p>

                                </div>
                            ) : (
                                <div className='infoPage'>
                                    <h1>
                                        Liebe(r) {orderData.firstName}!
                                    </h1>
                                    <h1>
                                        Wir haben Deine Daten bereits erhalten und die sind jetzt in Bearbeitung!
                                    </h1>
                                    <h1>
                                        Vielen Dank!
                                    </h1>
                                    <div className='firework'>
                                        {/* <Fireworks /> */}
                                        <div class="container1">
                                            <div class="bike-body">
                                                <div class="seat"></div>
                                                <div class="cover"></div>
                                                <div class="lamp"></div>
                                                <div class="motor">
                                                    <div class="part-1">
                                                        <div class="part-1-top"></div>
                                                        <div class="part-1-bottom"></div>
                                                    </div>
                                                    <div class="part-2">
                                                        <div class="part-2-base">
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="front"></div>
                                                <div class="back"></div>
                                            </div>
                                            <div class="tire"></div>
                                            <div class="tire"></div>
                                            <div class="smoke"></div>
                                        </div>
                                    </div>
                                    <p>Dein motorrad-mieten-kaernten.at Team</p>
                                </div>

                            )
                            }
                        </>
                    ) : (
                        <div className='formIn' id='myForm'>


                            <form onSubmit={handleSubmit}>
                                <div className='form'>

                                    <div className='fullForm'>

                                        <div>

                                            <div className='formLeft'>

                                                <div className='firstForm' style={location.pathname.startsWith("/orders") ? { marginTop: '0px' } : {}}>


                                                    <div style={{ width: '100%' }}>

                                                        {location.pathname.startsWith("/orders") ? (<></>) : (<h2>Wähle dein Motorrad und den Zeitraum</h2>)}


                                                        {orderData ? (
                                                            <></>
                                                        ) : (
                                                            <div>
                                                                <div>

                                                                    <h3>1. Motorrad</h3>
                                                                    {orderData ? (
                                                                        // Форма для случая, когда orderData существует
                                                                        <div>
                                                                            {orderData.statusMotorcycle1 ? (
                                                                                // Дополнительное условие для случая, когда statusMotorcycle1 существует

                                                                                <div className='wahl'>
                                                                                    <label className='label1' htmlFor="motorcycle">1. Wahl</label>
                                                                                    <select
                                                                                        id="motorcycle"
                                                                                        // value={location.pathname.startsWith("/orders") && orderStatus && orderData ? orderData.selectedMotorcycle : selectedMotorcycle}
                                                                                        value={location.pathname.startsWith("/orders") && orderData ? orderData.selectedMotorcycle : selectedMotorcycle}
                                                                                        onChange={handleMotorcycleSelectChange}
                                                                                        required
                                                                                        // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                        disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                                    >
                                                                                        <option value="">Motorrad auswählen</option>
                                                                                        {motorcycles.map(motorcycle => (
                                                                                            <option key={motorcycle.id} value={motorcycle.Name}>{motorcycle.Name}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>

                                                                            ) : null}
                                                                        </div>
                                                                    ) : (
                                                                        // Форма для случая, когда orderData отсутствует
                                                                        <div className='wahl'>
                                                                            <label className='label1' htmlFor="motorcycle">1. Wahl</label>
                                                                            <select
                                                                                id="motorcycle"
                                                                                // value={location.pathname.startsWith("/orders") && orderStatus && orderData ? orderData.selectedMotorcycle : selectedMotorcycle}
                                                                                value={location.pathname.startsWith("/orders") && orderData ? orderData.selectedMotorcycle : selectedMotorcycle}
                                                                                onChange={handleMotorcycleSelectChange}
                                                                                required
                                                                                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                            >
                                                                                <option value="">Motorrad auswählen</option>
                                                                                {motorcycles.map(motorcycle => (
                                                                                    <option key={motorcycle.id} value={motorcycle.Name}>{motorcycle.Name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    )}


                                                                    {orderData ? (
                                                                        // Форма для случая, когда orderData существует
                                                                        <div>
                                                                            {orderData.statusMotorcycle2 ? (
                                                                                // Дополнительное условие для случая, когда statusMotorcycle1 существует

                                                                                <div className='wahl'>

                                                                                    <label className='label2' htmlFor="motorcycle2">Alternativ</label>
                                                                                    <select
                                                                                        id="motorcycle2"
                                                                                        // value={location.pathname.startsWith("/orders") && orderStatus && orderData ? orderData.selectedMotorcycle2 : selectedMotorcycle2}
                                                                                        value={location.pathname.startsWith("/orders") && orderData ? orderData.selectedMotorcycle2 : selectedMotorcycle2}
                                                                                        onChange={handleMotorcycle2SelectChange}
                                                                                        // required
                                                                                        // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                        disabled={location.pathname.startsWith("/orders") && !!orderData}

                                                                                    >
                                                                                        <option value="">Motorrad auswählen</option>
                                                                                        {motorcycles.map(motorcycle => (
                                                                                            <option key={motorcycle.id} value={motorcycle.Name}>{motorcycle.Name}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>

                                                                            ) : null}
                                                                        </div>
                                                                    ) : (
                                                                        // Форма для случая, когда orderData отсутствует
                                                                        <div className='wahl'
                                                                            style={{
                                                                                visibility: sessionStorage.getItem('userId') ? 'hidden' : 'visible'
                                                                            }}
                                                                        >


                                                                            <label className='label2' htmlFor="motorcycle2">Alternativ</label>
                                                                            <select
                                                                                id="motorcycle2"
                                                                                // value={location.pathname.startsWith("/orders") && orderStatus && orderData ? orderData.selectedMotorcycle2 : selectedMotorcycle2}
                                                                                value={location.pathname.startsWith("/orders") && orderData ? orderData.selectedMotorcycle2 : selectedMotorcycle2}
                                                                                onChange={handleMotorcycle2SelectChange}
                                                                                // required
                                                                                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}

                                                                            >
                                                                                <option value="">Motorrad auswählen</option>
                                                                                {motorcycles.map(motorcycle => (
                                                                                    <option key={motorcycle.id} value={motorcycle.Name}>{motorcycle.Name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    )}


                                                                    {/* <div className='wahl'>

                                             <label className='label2' htmlFor="motorcycle2">Alternativ</label>
                                             <select
                                          id="motorcycle2"
                                               value={location.pathname.startsWith("/orders") && orderStatus && orderData ? orderData.selectedMotorcycle2 : selectedMotorcycle2}
                                               onChange={handleMotorcycle2SelectChange}
                                              // required
                                                 disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}

                                        >
                                        <option value="">Motorrad auswählen</option>
                                         {motorcycles.map(motorcycle => (
                                        <option key={motorcycle.id} value={motorcycle.Name}>{motorcycle.Name}</option>
                                        ))}
                                           </select>
                                           </div> */}

                                                                </div>

                                                                <div className='tarif'>
                                                                    <h3>2. Mietzeitraum</h3>
                                                                    <label className="label label_a">
                                                                        <input
                                                                            className="radio radio_a"
                                                                            type="radio"
                                                                            value="daily"
                                                                            checked={selectedRate === 'daily'}
                                                                            onChange={() => handleRateChange('daily')} // Обработчик изменения тарифа для ежедневной ставки
                                                                            disabled={location.pathname.startsWith("/orders") && orderData && orderData.selectedRate ? true : false} // Если тариф выбран из orderData, то поле будет disabled

                                                                        />
                                                                        {/* <p>
                                                                            Tagesmieten (Mo - Fr 8:30 - 17:30)
                                                                        </p> */}
                                                                        <p>
                                                                            Tagesmieten (Mo - Fr 8:30 - 16:30)
                                                                        </p>
                                                                    </label>
                                                                    <label className="label label_b">
                                                                        <input
                                                                            className="radio radio_b"
                                                                            type="radio"
                                                                            value="weekly"
                                                                            checked={selectedRate === 'weekly'}
                                                                            onChange={() => handleRateChange('weekly')} // Обработчик изменения тарифа для недельной ставки
                                                                            disabled={location.pathname.startsWith("/orders") && orderData && orderData.selectedRate ? true : false} // Если тариф выбран из orderData, то поле будет disabled

                                                                        />
                                                                        {/* <p>
                                                                            Wochenendmieten (Fr ab 15:00 - Mo 10:00)
                                                                        </p> */}
                                                                        <p>
                                                                            Wochenendmieten (Fr ab 16:30 - Mo 9:00)
                                                                        </p>
                                                                    </label>
                                                                    <label className="label label_c">
                                                                        <input
                                                                            className="radio radio_c"
                                                                            type="radio"
                                                                            value="combi"
                                                                            checked={selectedRate === 'combi'}
                                                                            onChange={() => handleRateChange('combi')}
                                                                            disabled={location.pathname.startsWith("/orders") && orderData && orderData.selectedRate ? true : false}
                                                                        />
                                                                        <p>
                                                                            Sonderanfragen/Kombinationen
                                                                        </p>
                                                                    </label>
                                                                </div>

                                                                <div className='dpContainer'>
                                                                    <CustomDatePicker
                                                                        setCombiDays={setCombiDays}
                                                                        setWeeklyPeriods={setWeeklyPeriods}
                                                                        startDate={startDate}
                                                                        endDate={endDate}
                                                                        setStartDate={setStartDate}
                                                                        setEndDate={setEndDate}
                                                                        selectedRate={selectedRate} // Передаем выбранный тариф
                                                                        selectedRate2={selectedRate2} // Передаем выбранный тариф
                                                                        selectedMotorcycle={selectedMotorcycle} // Передаем выбранный мотоцикл
                                                                        selectedMotorcycle2={selectedMotorcycle2} // Передаем выбранный мотоцикл
                                                                        motorcycles={motorcycles} // Передаем массив мотоциклов
                                                                        setDays={setDays} // Передаем функцию для установки количества дней
                                                                        setPrice={setPrice} // Передаем функцию для установки цены
                                                                        setPrice2={setPrice2} // Передаем функцию для установки цены
                                                                        setPriceWithoutPaket={setPriceWithoutPaket}
                                                                        setPrice2WithoutPaket={setPrice2WithoutPaket}
                                                                        startTime={startTime}
                                                                        endTime={endTime}
                                                                        setStartTime={setStartTime} // Pass setStartTime
                                                                        setEndTime={setEndTime} // Pass setEndTime
                                                                        formattedStartDate={formattedStartDate} // Важно добавить это
                                                                        formattedEndDate={formattedEndDate}
                                                                        formattedStartTime={formattedStartTime}
                                                                        formattedEndTime={formattedEndTime}
                                                                        zusatzpaketChecked={zusatzpaketChecked}

                                                                    />
                                                                </div>

                                                                <h3>3. Optional</h3>
                                                                <div className='zusatzPaket'>
                                                                    <div className='zusatz'>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="zusatzpaket"
                                                                            name="zusatzpaket"
                                                                            value="zusatzpaket"
                                                                            checked={zusatzpaketChecked}
                                                                            onChange={handleZusatzpaketChange}
                                                                            disabled={location.pathname.startsWith("/orders") && !!orderData}

                                                                        />
                                                                        <label className='zusatz_label' htmlFor="zusatzpaket">Zusatzpaket für 69,-</label>

                                                                    </div>

                                                                    <div className='zusatz_info'>
                                                                        <p>
                                                                            Inkludiert auch die Reduktion des Kasko Selbstbehaltes auf max. 1.000,00 Euro und gewährt Kasko Versicherungsschutz auch für Kroatien, Italien, Slowenien, Frankreich, Schweiz und Deutschland
                                                                        </p>

                                                                    </div>

                                                                </div>

                                                                <div id='btn1' className='btnContainer'>
                                                                    {!location.pathname.startsWith("/orders") && endDate && !sessionStorage.getItem('userId') && (
                                                                        <button
                                                                            className='btn'
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Предотвращаем отправку формы по умолчанию
                                                                                handleButtonClick2(); // Вызываем функцию для изменения класса формы
                                                                            }}                            >
                                                                            Jetzt Verfügbarkeit anfragen
                                                                        </button>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        )

                                                        }





                                                        {(location.pathname.startsWith("/orders") && orderData || sessionStorage.getItem('userId')) && (
                                                            <div>
                                                                <div className='personDaten2'>
                                                                    <div className='contactInfo2'>

                                                                        <h2>Persönliche Angaben</h2>

                                                                        <div className='field'>
                                                                            <label htmlFor="firstName">Vorname:</label>
                                                                            <input
                                                                                type="text"
                                                                                id="firstName"
                                                                                value={firstName}
                                                                                onChange={(e) => setFirstName(e.target.value)}
                                                                                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className='field'>
                                                                            <label htmlFor="lastName">Nachname:</label>
                                                                            <input
                                                                                type="text"
                                                                                id="lastName"
                                                                                value={lastName}
                                                                                onChange={(e) => setLastName(e.target.value)}
                                                                                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                                required
                                                                            />
                                                                        </div>

                                                                        <div className='field'>
                                                                            <label htmlFor="email">E-Mail-Adresse:</label>
                                                                            <input
                                                                                type="email"
                                                                                id="email"
                                                                                name="email"
                                                                                value={email}
                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                                // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                                required />
                                                                        </div>
                                                                        {/* <div className='field'>
                                                <label htmlFor="phoneNumber">Telefonnummer:</label>
                                                <input
                                                    type="tel"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    // disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                    disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                    required />
                                            </div> */}
                                                                        <div className='field' style={{ marginBottom: '5px' }}>
                                                                            <label htmlFor="phoneNumber">Telefonnummer:</label>
                                                                            <PhoneInput
                                                                                className={'phoneInput'}
                                                                                country={'at'} // Default country code and flag (Austria)
                                                                                value={phoneNumber}
                                                                                onChange={(phone) => setPhoneNumber(phone)}
                                                                                disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                                inputProps={{
                                                                                    name: 'phoneNumber',
                                                                                    required: true,
                                                                                    id: 'phoneNumber',
                                                                                }}

                                                                            />
                                                                        </div>

                                                                        <div className='field'>
                                                                            <label htmlFor="birthday">Geburtsdatum:</label>
                                                                            <BirthdayDatePicker selectedDate2={selectedDate2} setSelectedDate2={setSelectedDate2} handleInputChange2={handleInputChange2} />
                                                                        </div>
                                                                        {fieldErrors.birthday && <div className="error-message">{fieldErrors.birthday}</div>}
                                                                        <div className='field'>
                                                                            <label htmlFor="street">Straße:</label>
                                                                            <input type="text" id="street" name="street" onChange={(e) => handleInputChange(e, setStreet)} />
                                                                        </div>
                                                                        {fieldErrors.street && <div className="error-message">{fieldErrors.street}</div>}
                                                                        <div className='field'>
                                                                            <label htmlFor="houseNumber">Hausnummer:</label>
                                                                            <input type="text" id="houseNumber" name="houseNumber" onChange={(e) => handleInputChange(e, setHouseNumber)} />
                                                                        </div>
                                                                        {fieldErrors.houseNumber && <div className="error-message">{fieldErrors.houseNumber}</div>}

                                                                        <div className='field'>
                                                                            <label htmlFor="zipCode">PLZ:</label>
                                                                            <input type="text" id="zipCode" name="zipCode" onChange={(e) => handleInputChange(e, setZipCode)} />
                                                                        </div>
                                                                        {fieldErrors.zipCode && <div className="error-message">{fieldErrors.zipCode}</div>}

                                                                        <div className='field'>
                                                                            <label htmlFor="city">Ort:</label>
                                                                            <input type="text" id="city" name="city" onChange={(e) => handleInputChange(e, setCity)} />
                                                                        </div>
                                                                        {fieldErrors.city && <div className="error-message">{fieldErrors.city}</div>}







                                                                    </div>

                                                                    {/* {!location.pathname.startsWith("/orders") || !sessionStorage.getItem('userId') && (<button type="submit" className='submitBtn'>
                                            {sessionStorage.getItem('userId') ? 'Anfrage registrieren & PDF generieren' : 'Senden'}
                                        </button>)} */}


                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>



                                                </div>








                                            </div>


                                            {/* <div className='btnContainer'>
                    {location.pathname.startsWith("/orders") && !!orderData && (
                        <button
                            className='btn3'
                            onClick={(e) => {
                                e.preventDefault(); // Предотвращаем отправку формы по умолчанию
                                // toggleForm2Class(); // Вызываем функцию для изменения класса формы
                                handleButtonClick();
                            }}                            >
                            OK! RESTLICHE DATEN HINZUFÜGEN
                        </button>
                    )}
                </div> */}


                                        </div>









                                        {/* className={`${form2Class}`} */}

                                        <div >


                                            {renderFormRight()}
                                        </div>







                                    </div>


                                    {/* {!location.pathname.startsWith("/orders") && (<button type="submit" className='submitBtn'>
        {sessionStorage.getItem('userId') ? 'Anfrage registrieren & PDF generieren' : 'JETZT VERFÜGBARKEIT ANFRAGEN'}
     < /button>)} */}
                                    {/* <button type="submit" className='submitBtn'>
        {sessionStorage.getItem('userId') ? 'Anfrage registrieren & PDF generieren' : 'JETZT VERFÜGBARKEIT ANFRAGEN'}
     </button> */}




                                    <div className='motoDetails'>
                                        <MotorcycleDetails
                                            combiDays={combiDays}
                                            weeklyPeriods={weeklyPeriods}
                                            selectedMotorcycle={selectedMotorcycle}
                                            selectedMotorcycle2={selectedMotorcycle2}
                                            selectedRate={getSelectedMotorcycleRate(selectedMotorcycle)}
                                            selectedRate1={selectedRate}
                                            selectedRate2={getSelectedMotorcycleRate2(selectedMotorcycle2)}
                                            wochenend = {getSelectedMotorcycleRatePrice(selectedMotorcycle)}
                                            wochenend2 = {getSelectedMotorcycleRatePrice2(selectedMotorcycle2)}
                                            startDate={startDate}
                                            endDate={endDate}
                                            days={days}
                                            price={price}
                                            price2={price2}
                                            startTime={startTime}
                                            endTime={endTime}
                                            selectedMotorcycleImage={getSelectedMotorcycleImage(selectedMotorcycle)}
                                            selectedMotorcycleImage2={getSelectedMotorcycleImage2(selectedMotorcycle2)}
                                            zusatzpaketChecked={zusatzpaketChecked}
                                            priceWithoutPaket={priceWithoutPaket}
                                            price2WithoutPaket={price2WithoutPaket}
                                            handleZusatzpaketChange={handleZusatzpaketChange} // передаем функцию обработки изменения чекбокса
                                            location={location} // если необходимо, передаем объект location
                                            orderStatus={orderStatus} // если необходимо, передаем статус заказа
                                            orderData={orderData} // если необходимо, передаем данные заказа

                                            fieldClass={fieldClass}
                                            setFieldClass={setFieldClass}
                                            toggleFieldClass={toggleFieldClass}
                                        />
                                    </div>



                                </div>

                                <div id='btn2' className='btnContainer'>
                                    {!location.pathname.startsWith("/orders") && endDate && !sessionStorage.getItem('userId') && (
                                        <button
                                            className='btn'
                                            onClick={(e) => {
                                                e.preventDefault(); // Предотвращаем отправку формы по умолчанию
                                                handleButtonClick2(); // Вызываем функцию для изменения класса формы
                                            }}                            >
                                            Jetzt Verfügbarkeit anfragen
                                        </button>
                                    )}
                                </div>








                                {!orderStatus && !orderData && (
                                    <div className='lastFormElement'>

                                        <div id='personDaten' className={`${fieldClass}`}>


                                            <div className='personContainer'>
                                                <div>
                                                    <div className='persDat'>
                                                        <div style={{ width: '60%' }} >
                                                            <h3>Gleich ist es geschafft!</h3>
                                                            <p>Um deine Anfrage schnellstmöglich zu bearbeiten, benötigen wir nur noch deine gültigen Kontaktdaten.</p>
                                                            <p>Wir werden uns in Kürze bei dir melden.</p>

                                                        </div>
                                                        {/* <div style={{width: '40%'}}>
                            <h3 style={{fontSize: '15px'}}>
                                So gehen wir mit deinen Daten um
                            </h3>
                            <p style={{fontSize: '13px', paddingLeft:'0' }}>
                                Wir erheben oder verarbeiten deine personenbezogene Daten nur, wenn du uns diese Angaben
                                freiwillig, z.B. im Rahmen einer Anfrage mitteilst.
                                In diesem Fall werden deine Angaben aus der Anfrage inklusive der von dir dort angegebenen
                                Kontaktdaten zur Erfüllung des mit der Übermittlung verbundenen Zwecks, z.B. zur Bearbeitung
                                deiner Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und genutzt.
                                Wenn du uns mit der Erbringung einer Dienstleistung beauftragst, erheben, speichern und nutzen
                                wir deine personenbezogenen Daten grundsätzlich nur, soweit es für die Erbringung der
                                Dienstleistung oder die Durchführung des Vertrages notwendig ist.
                            </p>
                        </div> */}
                                                    </div>
                                                    <div className='contactContainer'>
                                                        <div className='contactInfo2'>
                                                            <div>
                                                                <div className='field'>

                                                                    <label htmlFor="firstName">Vorname:</label>

                                                                    <input
                                                                        type="text"
                                                                        id="firstName"
                                                                        value={firstName}
                                                                        onChange={(e) => handleInputChange(e, setFirstName)}
                                                                        disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                    // required
                                                                    />

                                                                </div>
                                                                {fieldErrors.firstName && <div className="error-message">{fieldErrors.firstName}</div>}
                                                            </div>



                                                            <div className='field'>
                                                                <label htmlFor="lastName">Nachname:</label>
                                                                <input
                                                                    type="text"
                                                                    id="lastName"
                                                                    value={lastName}
                                                                    onChange={(e) => handleInputChange(e, setLastName)}
                                                                    disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                // required
                                                                />
                                                            </div>
                                                            {fieldErrors.lastName && <div className="error-message">{fieldErrors.lastName}</div>}


                                                            <div className='field'>
                                                                <label htmlFor="email">E-Mail-Adresse:</label>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(e) => handleInputChange(e, setEmail)}
                                                                    disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                                                // required 
                                                                />
                                                            </div>
                                                            {fieldErrors.email && <div className="error-message">{fieldErrors.email}</div>}

                                                            {/* <div className='field'>
                                    <label htmlFor="phoneNumber">Telefonnummer:</label>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        disabled={location.pathname.startsWith("/orders") && orderStatus && !!orderData}
                                        required />
                                </div> */}

                                                            <div className='field' style={{ marginBottom: '5px' }}>
                                                                <label htmlFor="phoneNumber">Telefonnummer:</label>
                                                                <PhoneInput
                                                                    className={'phoneInput'}
                                                                    country={'at'} // Default country code and flag (Austria)
                                                                    value={phoneNumber}
                                                                    onChange={handlePhoneChange}
                                                                    disabled={location.pathname.startsWith("/orders") && !!orderData}
                                                                    inputProps={{
                                                                        name: 'phoneNumber',
                                                                        required: true,
                                                                        id: 'phoneNumber',
                                                                    }}
                                                                />
                                                            </div>
                                                            {fieldErrors.phoneNumber && <div className="error-message">{fieldErrors.phoneNumber}</div>}





                                                        </div>





                                                    </div>
                                                    <div className='datenText2' style={{ width: '100%' }}>
                                                    <h3>
                                                        So gehen wir mit deinen Daten um
                                                    </h3>
                                                    <p style={{ fontSize: '13px', paddingLeft: '0' }}>
                                                        Wir erheben oder verarbeiten deine personenbezogene Daten nur, wenn du uns diese Angaben
                                                        freiwillig, z.B. im Rahmen einer Anfrage mitteilst.
                                                        In diesem Fall werden deine Angaben aus der Anfrage inklusive der von dir dort angegebenen
                                                        Kontaktdaten zur Erfüllung des mit der Übermittlung verbundenen Zwecks, z.B. zur Bearbeitung
                                                        deiner Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und genutzt.
                                                        Wenn du uns mit der Erbringung einer Dienstleistung beauftragst, erheben, speichern und nutzen
                                                        wir deine personenbezogenen Daten grundsätzlich nur, soweit es für die Erbringung der
                                                        Dienstleistung oder die Durchführung des Vertrages notwendig ist.
                                                    </p>
                                                </div>

                                                    <div className='field1' style={{ marginTop: '30px', marginTop: '30px', gap: '10px' }}>
                                                        <input type="checkbox" id="agreements1" name="agreements1" checked={agb1Checked} onChange={handleAgb1Change} required />
                                                        <label htmlFor="agreements1">Ich bin mit den AGBs und Datenschutzbestimmungen einverstanden.</label>
                                                    </div>

                                                    {!location.pathname.startsWith("/orders") && (<button type="submit" className='btn2submit'>
                                                        {sessionStorage.getItem('userId') ? 'Anfrage registrieren & PDF generieren' : 'Senden'}
                                                    </button>)}
                                                </div>

                                                <div className='datenText' style={{ width: '40%' }}>
                                                    <h3 style={{ paddingTop: '33%' }}>
                                                        So gehen wir mit deinen Daten um
                                                    </h3>
                                                    <p style={{ fontSize: '13px', paddingLeft: '0' }}>
                                                        Wir erheben oder verarbeiten deine personenbezogene Daten nur, wenn du uns diese Angaben
                                                        freiwillig, z.B. im Rahmen einer Anfrage mitteilst.
                                                        In diesem Fall werden deine Angaben aus der Anfrage inklusive der von dir dort angegebenen
                                                        Kontaktdaten zur Erfüllung des mit der Übermittlung verbundenen Zwecks, z.B. zur Bearbeitung
                                                        deiner Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und genutzt.
                                                        Wenn du uns mit der Erbringung einer Dienstleistung beauftragst, erheben, speichern und nutzen
                                                        wir deine personenbezogenen Daten grundsätzlich nur, soweit es für die Erbringung der
                                                        Dienstleistung oder die Durchführung des Vertrages notwendig ist.
                                                    </p>
                                                </div>
                                            </div>




                                        </div>
                                    </div>



                                )}





                            </form>

                            {/* {sessionStorage.getItem('userId') && orderData && (
    <>
        <div className='pdfContainer'>
            <PDFComponent orderData={orderData} userFullName={userFullName} />
            <PDFComponent2 orderData={orderData} userFullName={userFullName} />
            <PDFComponent3 orderData={orderData} userFullName={userFullName} />
        </div>


    </>




)} */}

                        </div>
                    )
                }



























            </div >
            {/* ) : ( */}
            {/* <div>
                    <h1>
                        Ihre Daten sind bereits eingetroffen! Danke!
                    </h1>

                </div>
            )} */}

        </div >
    );



}

export default MotorcycleForm;
